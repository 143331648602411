import {Link} from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import headerStyles from '../Styles/header.module.scss';
import {Container} from 'react-bootstrap';
import * as utils from './Utils';
import ShawacademyLogo from '../../../static/images/logo/logo-new.inline.svg';

/**
 *
 *
 * @param {*} {locale, headerContent}
 * @return {*}
 */
const Header =
({locale, hideCatalogueLink, courseCatalogue}) => {
  // eslint-disable-next-line no-unused-vars
  // const {allShawWebsiteFacultypage}=headerData();
  // const facultyData = allShawWebsiteFacultypage.edges;

  // const handleClickOutside = (event) => {
  //   if ((dropdownRef.current &&
  //     !dropdownRef.current.contains(event.target)) ||
  //     (drodownCloseRef.current &&
  //     drodownCloseRef.current.contains(event.target))) {
  //     triggerDropdown(false);
  //     revertDropdownContent(event);
  //   }
  // };

  // useEffect(() => {
  //   if (window.innerWidth < 640) {
  //     setFacultyDropdownState('');
  //   }
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // }, []);

  // const revertDropdownContent = (e) => {
  //   e.stopPropagation();
  //   triggerDropdown(false);
  //   if (window.innerWidth < 640) {
  //     setFacultyDropdownState('');
  //   }
  // };
  /**
   *
   *
   * @param {*} data
   * @return {*}
   */
  // const filterLocaleBasedData = (data) => {
  //   const filteredData = data.filter((node) =>
  //     node.publish_details.locale === locale);
  //   return filteredData;
  // };

  /**
   *
   *
   * @param {*} facultyData
   * @return {*}
   */
  // const getFaculties = (facultyData) => {
  //   const filteredFaculties = facultyData
  //       .filter(({node}) => node.publish_details.locale === locale);
  //   const facultyMap={};
  //   filteredFaculties.forEach((faculty) => {
  //     facultyMap[faculty.node.facultyslug]=faculty.node;
  //   });
  //   return facultyMap;
  // };

  /**
   *
   *
   * @param {*} courseData
   * @param {*} facultyData
   * @return {*}
   */
  // const segregateCourses = (courseData, facultyData) => {
  //   const facultyMap = {};
  //   Object.keys(facultyData).forEach((faculty) => {
  //     facultyMap[faculty]={...facultyData[faculty]};
  //     facultyMap[faculty].courseList = [];
  //     courseData.forEach((course) => {
  //       course?.reference?.forEach((ref) => {
  //         if (ref.facultyslug.toLowerCase() === faculty) {
  //           const data = {
  //             title: course.title,
  //             url: utils.getLocalizedPageLink(locale, course.url),
  //             menuName: course.coursemenuname,
  //             rating: course.rating,
  //             graduates: course.graduates,
  //             slug: course.courseslug,
  //           };
  //           facultyMap[faculty].courseList.push(data);
  //         }
  //       });
  //     });
  //   });
  //   return Object.keys(facultyMap)
  //       .map((ele) => (facultyMap[ele]));
  // };

  // const getFirstFaculty = (facultyList) => {
  //   let firstFaculty='';
  //   firstFaculty=facultyList.filter((faculty) => {
  //     return faculty.courseList.length;
  //   })[0]?.facultyslug;
  //   return firstFaculty;
  // };

  // const facultyMap = getFaculties(facultyData);
  // const filteredCourseData = filterLocaleBasedData(courseData);
  // const facultyList = segregateCourses(filteredCourseData, facultyMap);
  // const [showDropdown, triggerDropdown] = useState(false);
  // const firstFaculty=getFirstFaculty(facultyList);

  // const [showFaculty, setFacultyDropdownState] = useState(firstFaculty);
  // const dropdownRef = useRef();
  // const drodownCloseRef = useRef();
  const homePageLink = utils.getLocalizedPageLink(locale, '/');
  return (
    <>
      <header className={headerStyles.header}>
        <Container className={headerStyles.container}>
          <div className={headerStyles.headerTopSection}>
            <div className={headerStyles.headerLogo}>
              <Link to={homePageLink}>
                <ShawacademyLogo />
              </Link>
              <p className={headerStyles.divider}></p>
              <div className={headerStyles.headerMoto}>
                <a href={process.env.EnterPriseDomain}>
                  Enterprise
                </a>
              </div>
            </div>
            <div className={headerStyles.headerContent}>
              <a href={`/app/${['en-gb', 'en-us'].includes(locale)?
              'en':locale}/login/`}
              className={`${headerStyles.loginCta} ${headerStyles.shawBtn}`}>
                Login
              </a>
            </div>
          </div>
        </Container>
      </header>
    </>
  );
};

Header.defaultProps = {
  data: {},
};

Header.propTypes = {
  data: PropTypes.object,
  locale: PropTypes.string,
  cataloguePageURL: PropTypes.string,
  headerContent: PropTypes.array,
  hideCatalogueLink: PropTypes.bool,
  courseCatalogue: PropTypes.string,
};
export default Header;
