import {Link} from 'gatsby';
import React from 'react';
import * as utils from './Utils';
import {Container} from 'react-bootstrap';
import footerStyles from '../Styles/footer.module.scss';
import footerPageData from '../../../data/homepage/shawHomePage.json';

const Footer = () => {
  const data = footerPageData.footer[0];

  return (
    <>
      <footer id="footer" className={footerStyles.footer}>
        <Container className={footerStyles.container}>
          <div className={footerStyles.copyrightSection}>
            <div className={footerStyles.copyrights}>
              <p>{data['single_line']}</p>
            </div>
            <div className={footerStyles.policyLinks}>
              {data.bottomnavigation
                  .map((navs, index)=>{
                    if (navs.externallink) {
                      return <a key={index} href={navs.externallink}>
                        {navs.title}</a>;
                    }
                    const link =
                    utils.getLocalizedPageLink(data.locale, navs.url);
                    return <Link key={index} to={link}>{navs.title}</Link>;
                  })}
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
